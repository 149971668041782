import React from 'react'
import Loader from './loader'
import Header from './header'
import Navbar from './navbar'
import { Helmet } from 'react-helmet'



const Layout = ({pageTitle, children}) => {

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{pageTitle}</title>
                <meta name="description" content="PragICTS AI services are designed to democratize AI for enterprise adoption, enabling businesses to significantly enhance productivity, performance, decision-making, and growth." />
                <meta name="keywords" content="AI, Generative AI, AGI, OpenAI, AI Services, PragICTS AI Services" />
                <meta property="og:title" content="PragICTS | AI Services" />
                <meta property="og:type" content="website" />
                <meta property="og:image" content="https://s3.ap-southeast-1.amazonaws.com/itops.pragicts.com/1.png" />
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="630" />
                <meta property="og:image:type" content="image/png" />
                <meta name="twitter:card" content="summary_large_image" />

                <meta property="og:description" content="PragICTS AI services are designed to democratize AI for enterprise adoption, enabling businesses to significantly enhance productivity, performance, decision-making, and growth.
" />
                <meta property="og:site_name" content="PragICTS" />


                <meta property="og:type" content="PragICTS" />
                <meta property="og:site_name" content="PragICTS | CloudOps Services" />
                <meta name="og:email" content="info@pragICTS.com" />
                <meta name="og:phone_number" content="00 94 76 298 8284" />
                <link rel="canonical" href="https://pragicts.com" />
                <script type="text/javascript" src="https://widget.clutch.co/static/js/widget.js"></script> 
            </Helmet>
            <Loader />
            <div id="main">
                <Header />
                <Navbar />
                {children}
            </div>
        </>
    )
}

export default Layout
